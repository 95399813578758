import { Accordion, Card, Container } from "react-bootstrap";
import Contact from "../contact/Contact";
import "./Correction2.css";
import { useScrollToTop } from "../../context/ScrollToTopContext";

const Correction2 = () => {
  const { scrollToTop } = useScrollToTop();
  return (
    <main className="correctionContainer">
      <section className="correctionBanner">
        <img
          src="https://res.cloudinary.com/diqry3ihg/image/upload/v1698770397/TranslatorLandingPage/Escribir_bien_-_LinkedIn_Banner_2_ppemvr.png"
          alt="Correction banner"
        />
      </section>
      <section className="correctionLogoContainer">
        <div>
          <img
            src="https://res.cloudinary.com/diqry3ihg/image/upload/v1698766638/TranslatorLandingPage/Escribir_bien_no_cuesta_nada_-_png_qiqlez.png"
            alt="Correction logo"
            className="correctionLogo"
          />
        </div>
        <div>
          <h3 className="correctionLogoText">
            Servicio de corrección altamente especializado y confiable enfocado
            en elevar la calidad de tus documentos mejorando aspectos de
            redacción, sintaxis, formato, ortografía y más.
          </h3>
        </div>
      </section>
      <section className="sectionDescription">
        <article>
          <p>
            "Escribir bien no cuesta nada" surge de mi práctica profesional como
            Traductor Público. A lo largo de mi carrera, al traducir textos del
            español al inglés, he notado la presencia de determinados errores en
            los textos originales. Estos errores pueden abarcar desde simples
            errores ortográficos hasta cuestiones más complejas como redacción,
            sintaxis, formato, y mucho más.
          </p>
          <p>
            A mi entender, esto tiene un gran impacto en la imagen de
            profesionalidad que damos a nuestros clientes. No debemos dar por
            sentado la importancia de un texto bien redactado y sin errores.
          </p>
          <p>
            Es por ello que decidí ayudar a profesionales a elevar la calidad de
            sus documentos y mejorar su imagen de profesionalidad, revisando y
            corrigiendo sus textos.
          </p>
          <p>
            Te invito a contactarme para que charlemos acerca de cómo puedo
            ayudarte.
          </p>
        </article>
      </section>
      <section className="sectionDescription">
        <article className="articleDescription">
          <div>
            <h3>
              En mi trayectoria de más de 5 años como Traductor, he detectado
              que se repiten CIERTOS PROBLEMAS:
            </h3>
            <ul>
              <li>
                ¿Alguna vez sufriste problemas relacionados con la redacción,
                sintaxis, formato, ortografía, etc., de tus documentos?
              </li>
              <li>
                ¿Alguna vez sentiste que la corrección y revisión puede ser una
                tarea tediosa y compleja?
              </li>
            </ul>
          </div>
          <div>
            <h3>
              Con este servicio, gracias al programa "Escribir bien no cuesta
              nada", VAS A CONSEGUIR:
            </h3>
            <ul>
              <li>
                Mejorar la calidad general de tus documentos, resolviendo
                aspectos relativos a redacción, sintaxis, formato, ortografía,
                etc.
              </li>
              <li>
                Enfocarte en tu área de genialidad que es tu profesión y delegar
                la parte más compleja en cuanto a la gramática.
              </li>
              <li>
                Obtener herramientas para poder ir elevando la calidad tú mismo.
              </li>
            </ul>
          </div>
        </article>
      </section>
      <section className="sectionDescription">
        <article className="text-center">
          <div className="picture mb-3">
            <img
              src="https://res.cloudinary.com/diqry3ihg/image/upload/v1665774401/TranslatorLandingPage/AboutMePicture-black_ob0uxd.png"
              alt="Manuel Sanson Prado"
            />
          </div>
        </article>
        <article className="text-center">
          <h3 className="correctionLogoText">MANUEL SANSON</h3>
          <h4 className="correctionLogoText">
            Traductor Público especializado en documentos legales
          </h4>
        </article>
        <article className="p-4">
          <p>
            Mi nombre es Manuel Sanson y soy Licenciado en Traducción Pública
            con más de cinco años de experiencia en el campo de la traducción
            legal. Brindo servicios de traducción legal altamente especializados
            y confiables a estudios jurídicos, abogados, escribanos y distintas
            empresas. A lo largo de mi carrera como Traductor he notado la
            presencia de determinados errores en los textos originales, desde
            simples errores ortográficos hasta cuestiones más complejas como
            redacción, sintaxis, formato y otros. Con una sólida formación
            académica en la Universidad de Montevideo y un profundo conocimiento
            de la terminología legal, mi enfoque se centra en lograr que sus
            documentos cumplan con los más altos estándares de calidad,
            guiándolos en todo el proceso de redacción y brindando traducciones
            que reflejen fielmente el contenido y propósito original, asegurando
            la precisión y coherencia en ambos idiomas. Reconozco la importancia
            de los plazos en el ámbito legal y me comprometo a entregar mis
            trabajos de manera puntual, cumpliendo con los requerimientos de mis
            clientes.
          </p>
        </article>
      </section>
      <section className="sectionDescription text-center">
        <h3> Ejemplo de cómo puedo ayudarte </h3>
        <img
          className="mt-2 example"
          src="https://res.cloudinary.com/diqry3ihg/image/upload/v1699290909/TranslatorLandingPage/correciones_enkqbs.png"
          alt="Document review"
        />
      </section>
      <section className="sectionDescription">
        <article className="articleDescription">
          <h3> ¿Qué incluye esta propuesta? </h3>
          <ul>
            <li>Diagnóstico</li>
            <li>Análisis</li>
            <li>Revisión</li>
            <li>Corrección</li>
            <li>Documento mejorado</li>
            <li>Feedback</li>
            <li>Sesiones</li>
            <li>Asesoría</li>
          </ul>
        </article>
      </section>
      <section className="sectionDescription">
        <article className="articleDescription">
          <h3> ¿Cómo vamos a trabajar? </h3>
          <h3>
            {" "}
            ¿Cómo es el proceso para mejorar la calidad de tus documentos?{" "}
          </h3>
          <img
            className="mt-3 workflow"
            src="https://res.cloudinary.com/diqry3ihg/image/upload/v1698852717/TranslatorLandingPage/workflow-png_qasr9q.png"
            alt="Workflow"
          />
        </article>
      </section>
      <section className="sectionDescription">
        <article className="articleDescription">
          <h3> Planes </h3>
          <div className="specializedServices correctionPlans">
            <Card className="serviceCard bg-light align-items-center text-center m-1 mb-3">
              <Card.Body>
                <Card.Title className="serviceCardTitle">
                  {" "}
                  <h3> 3000 palabras </h3>{" "}
                </Card.Title>
                <Card.Text className="serviceCardText p-2">
                  {" "}
                  Este plan cubre la revisión y corrección de hasta 3000
                  palabras e incluye diagnóstico, análisis, revisión, corrección
                  y el documento mejorado con cambios y comentarios.{" "}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="serviceCard bg-light align-items-center text-center m-1 mb-3">
              <Card.Title className="serviceCardTitle">
                {" "}
                <h3> 5000 palabras </h3>{" "}
              </Card.Title>
              <Card.Text className="serviceCardText p-2">
                {" "}
                Este plan cubre la revisión y corrección de hasta 5000 palabras
                e incluye diagnóstico, análisis, revisión, corrección, el
                documento mejorado con cambios y comentarios y feedback y
                asesoría de cómo mejorar la calidad general del documento.{" "}
              </Card.Text>
            </Card>
            <Card className="serviceCard bg-light align-items-center text-center m-1 mb-3">
              <Card.Title className="serviceCardTitle">
                {" "}
                <h3> 10000 palabras </h3>{" "}
              </Card.Title>
              <Card.Text className="serviceCardText p-2">
                {" "}
                Este plan cubre la revisión y corrección de hasta 10000 palabras
                e incluye diagnóstico, análisis, revisión, corrección, el
                documento mejorado con cambios y comentarios, feedback y
                asesoría de cómo mejorar la calidad general del documento y
                sesiones para discutir problemas específicos.{" "}
              </Card.Text>
            </Card>
          </div>
          <p>
            Si quieres recibir más información acerca de estos planes o una
            solución específica para tus necesidades, por favor contactame.
          </p>
          <p>
            Además, este servicio cuenta con la garantía de que si luego del
            primer trabajo no es lo que esperabas, te devolveré el dinero.
          </p>
        </article>
      </section>
      <section className="sectionDescription">
        <article className="articleDescription">
          <h3> ¿Aún estas dudando? </h3>
          <h3> ¿Cuánto te costaría? </h3>
          <p>Te propongo a que lo pienses de la siguiente manera:</p>
          <ul>
            <li>
              ¿Cómo impactaría a tu imagen presentar un escrito que contenga
              errores?
            </li>
            <li>
              ¿Cómo se vería afectada tu profesionalidad si tu escrito tiene
              fallas en la redacción y sintaxis?
            </li>
            <li>
              ¿Cómo percibirían los demás si tus documentos presentan un error
              de ortografía u otros aspectos gramaticales?
            </li>
            <li>
              ¿Cuánto tiempo le dedicas a esta actividad de corrección y
              revisión que podrías enfocar en otras tareas?
            </li>
            <h3>
              {" "}
              PODÉS AHORRARTE TODOS ESTOS PROBLEMAS CONTRATANDO ESTE SERVICIO.{" "}
            </h3>
          </ul>
        </article>
      </section>
      <section className="sectionDescription">
        <article className="articleDescription">
          <h3> Lo que vas a obtener contratando este servicio </h3>
          <ul>
            <li>
              {" "}
              <b> Mejorar la calidad de tus escritos. </b>
              Recibirás tu documento revisado y corregido con los cambios y
              comentarios apropiados. Además tendrás la oportunidad de recibir
              feedback y asesoría para mejorar la calidad de tus documentos.
              Aprovecha las sesiones para tratar temas específicos que te
              generen dudas.
            </li>
            <li>
              {" "}
              <b> Aumentar la sensación de profesionalidad. </b>
              Al garantizar que tus documentos estarán libres de errores de
              redacción, sintaxis, gramática, formato, etc., podrás reflejar una
              mayor imagen de profesionalidad y concentrarte en tu especialidad.
            </li>
            <li>
              {" "}
              <b> No tendrás que preocuparte por los plazos. </b>
              El trabajo se entregará con la mayor celeridad posible para que
              puedas cumplir a tiempo con cualquier tipo de trámite.
            </li>
            <li>
              {" "}
              <b> Conocimiento específico en la materia. </b>
              Gracias a mis más de cinco años de experiencia en el campo de la
              traducción legal y mi sólida formación académica, recibirás un
              servicio de altamente especializado para tus documentos.
            </li>
            <li>
              {" "}
              <b> Precisión y calidad. </b>
              Mi enfoque se centra en mejorar tus documentos pero siempre de
              forma que siempre reflejen fielmente el contenido y propósito
              original.
            </li>
            <li>
              {" "}
              <b> Tranquilidad. </b>
              Lo más importante de todo esto proceso es que puedas quedarte
              tranquilo de que recibirás un servicio de alta calidad,
              especializado en el área y con una muy rápida entrega.
            </li>
          </ul>
        </article>
      </section>
      <Container className="p-3 mb-5 questionsContainer">
        <h3 className="text-center">Preguntas frecuentes</h3>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              ¿Cuál es tu experiencia con documentos legales?{" "}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              Soy Licenciado en Traducción Pública y gracias a mi sólida
              formación académica en la Universidad de Montevideo poseo un
              profundo conocimiento de la terminología legal. Asimismo, cuento
              con más de 5 años de experiencia en el campo de la traducción
              legal. Me desempeño como traductor independiente desde el año 2017
              y desde 2019 trabajo con un importante estudio de traducción de
              Montevideo.{" "}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {" "}
              ¿Con qué tipos de documentos legales trabajas?{" "}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              Me especializo en diversos documentos de índole legal tales como
              contratos, estatutos y otros documentos societarios, poderes,
              certificaciones y legalizaciones.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>¿Cuánto demora el trabajo? </Accordion.Header>
            <Accordion.Body>
              {" "}
              Este punto depende del tipo de documento y su extensión, pero sí
              te puedo asegurar que lo tendré pronto a tiempo para que puedas
              realizar el trámite que corresponda. Para poder brindarte una idea
              más exacta de cuánto puede demorar la traducción del documento que
              necesitas, contáctame.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {" "}
              ¿Hay un límite máximo de palabras?{" "}
            </Accordion.Header>
            <Accordion.Body>
              {" "}
              No, el plan más completo incluye hasta 10000 palabras. Pero si tu
              texto es más largo, no hay problema. Contáctame y podré darte más
              detalles.{" "}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <section className="sectionDescription">
        <article className="articleDescription">
          <h3> Me encantaría poder ayudarlos a alcanzar sus objetivos. </h3>
          <p>
            Realmente me entusiasma mucho el poder trabajar juntos y lograr
            llevar sus documentos al siguiente nivel.{" "}
          </p>
        </article>
      </section>
      <Contact />
      <footer className="footerContainer">
        <div className="footerInfoContainer">
          <div className="text-center correctionLogoSocialMedia">
            <div>
              <img
                src="https://res.cloudinary.com/diqry3ihg/image/upload/v1698766638/TranslatorLandingPage/Escribir_bien_no_cuesta_nada_-_png_qiqlez.png"
                alt="Correction Logo"
                onClick={scrollToTop}
                className="footerCorrectionLogo"
              />
            </div>
            <div className="linkedIn">
              <span className="p-2">
                <a
                  href="https://www.linkedin.com/company/escribir-bien-no-cuesta-nada"
                  target="blank"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/240px-LinkedIn_logo_initials.png"
                    alt="Linkedin logo"
                  />
                </a>
              </span>
              <span className="p-2">
                <a
                  href="https://www.instagram.com/escribir_bien_no_cuesta_nada/"
                  target="blank"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1024px-Instagram_logo_2022.svg.png"
                    alt="Instagram logo"
                  />
                </a>
              </span>
              <span className="p-2">
                <a
                  href="https://www.facebook.com/people/Escribir-bien-no-cuesta-nada/61553198932863/"
                  target="blank"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1024px-2023_Facebook_icon.svg.png"
                    alt="Facebook logo"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="pt-5 pb-2 text-center">
          <h6> MANUEL SANSON TRADUCCIONES - TODOS LOS DERECHOS RESERVADOS </h6>
        </div>
        <div className="development pt-2 pb-1 text-center">
          <h6> Ir a MS Traducciones </h6>
          <a
            href="https://www.ms-traducciones.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/diqry3ihg/image/upload/v1667418506/TranslatorLandingPage/MSTraducciones_Logo_daheut.png"
              alt="MS Traducciones Logo"
            />
          </a>
        </div>
      </footer>
    </main>
  );
};

export default Correction2;
