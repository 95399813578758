import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import NavBar from "./components/navbar/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import AboutMe from "./components/aboutMe/AboutMe";
import CommButton from "./components/commButtons/CommButtons";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
//import Correction from "./components/correction/Correction";
import ScrollToTopProvider from "./context/ScrollToTopContext";
import Correction2 from "./components/correction/Correction2";

function App() {
  if (window.location.pathname === "/escribir-bien-no-cuesta-nada") {
    return (
      <BrowserRouter>
        <ScrollToTopProvider>
          <Routes>
            <Route path="*" element={<Correction2 />} />
          </Routes>
        </ScrollToTopProvider>
      </BrowserRouter>
    );
  }
  // if (window.location.pathname !== "/") {
  //   return (
  //     <ScrollToTopProvider>
  //       <Correction2 />
  //     </ScrollToTopProvider>
  //   );
  //}
  return (
    <BrowserRouter>
      <NavBar />
      <CommButton />
      <ScrollToTopProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </ScrollToTopProvider>
    </BrowserRouter>
  );
}

export default App;
